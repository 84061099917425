import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // Home
  { path: '', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), data: { breadcrumb: "Homepage" } },
  //{ path: 'home-v2', loadChildren: () => import('./components/pages/home-two/home-two.module').then(m => m.HomeTwoModule), data: { breadcrumb: "Homepage" } },
  // Pages
  { path: 'contact', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: "Contact Us" } },
  { path: 'about', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: "About Us" } },
  { path: 'faqs', loadChildren: () => import('./components/pages/faqs/faqs.module').then(m => m.FaqsModule), data: { breadcrumb: "FAQ's" } },
  { path: 'login', loadChildren: () => import('./components/pages/login/login.module').then(m => m.LoginModule), data: { breadcrumb: "Login" } },
  { path: 'register', loadChildren: () => import('./components/pages/register/register.module').then(m => m.RegisterModule), data: { breadcrumb: "Register" } },
  { path: 'error-404', loadChildren: () => import('./components/pages/error/error.module').then(m => m.ErrorModule), data: { breadcrumb: "Error 404" } },
  { path: 'coming-soon', loadChildren: () => import('./components/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule), data: { breadcrumb: "Coming Soon" } },
  { path: 'wishlist', loadChildren: () => import('./components/pages/wishlist/wishlist.module').then(m => m.WishlistModule), data: { breadcrumb: "Wishlist" } },
  { path: 'cart', loadChildren: () => import('./components/pages/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: "Cart" } },
  { path: 'checkout', loadChildren: () => import('./components/pages/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: "Checkout" } },
  // Blog
  { path: 'blog/cat/:catId', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog/tag/:tagId', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog/author/:authorId', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog/search/:query', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog-left', loadChildren: () => import('./components/pages/blog-left/blog-left.module').then(m => m.BlogLeftModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog-right', loadChildren: () => import('./components/pages/blog-right/blog-right.module').then(m => m.BlogRightModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog-list', loadChildren: () => import('./components/pages/blog-list/blog-list.module').then(m => m.BlogListModule), data: { breadcrumb: "Blog List" } },
  { path: 'blog-list-left', loadChildren: () => import('./components/pages/blog-list-left/blog-list-left.module').then(m => m.BlogListLeftModule), data: { breadcrumb: "Blog List" } },
  { path: 'blog-list-right', loadChildren: () => import('./components/pages/blog-list-right/blog-list-right.module').then(m => m.BlogListRightModule), data: { breadcrumb: "Blog List" } },
  { path: 'blog-details/:id', loadChildren: () => import('./components/pages/blog-details/blog-details.module').then(m => m.BlogDetailsModule), data: { breadcrumb: "Blog Details" } },
  { path: 'blog-details-left/:id', loadChildren: () => import('./components/pages/blog-details-left/blog-details-left.module').then(m => m.BlogDetailsLeftModule), data: { breadcrumb: "Blog Details" } },
  { path: 'blog-details-right/:id', loadChildren: () => import('./components/pages/blog-details-right/blog-details-right.module').then(m => m.BlogDetailsRightModule), data: { breadcrumb: "Blog Details" } },
  // Shop
  { path: 'shop/cat/:catId', loadChildren: () => import('./components/pages/shop-grid/shop-grid.module').then(m => m.ShopGridModule), data: { breadcrumb: "Shop Grid" } },
  { path: 'shop/tag/:tagId', loadChildren: () => import('./components/pages/shop-grid/shop-grid.module').then(m => m.ShopGridModule), data: { breadcrumb: "Shop Grid" } },
  { path: 'shop/search/:query', loadChildren: () => import('./components/pages/shop-grid/shop-grid.module').then(m => m.ShopGridModule), data: { breadcrumb: "Shop Grid" } },
  { path: 'shop/:minPrice/:maxPrice', loadChildren: () => import('./components/pages/shop-grid/shop-grid.module').then(m => m.ShopGridModule), data: { breadcrumb: "Shop Grid" } },
  { path: 'shop', loadChildren: () => import('./components/pages/shop-grid/shop-grid.module').then(m => m.ShopGridModule), data: { breadcrumb: "Shop Grid" } },
  { path: 'shop-grid-full', loadChildren: () => import('./components/pages/shop-grid-full/shop-grid-full.module').then(m => m.ShopGridFullModule), data: { breadcrumb: "Shop Grid" } },
  { path: 'shop-grid-left', loadChildren: () => import('./components/pages/shop-grid-left/shop-grid-left.module').then(m => m.ShopGridLeftModule), data: { breadcrumb: "Shop Grid" } },
  { path: 'shop-grid-right', loadChildren: () => import('./components/pages/shop-grid-right/shop-grid-right.module').then(m => m.ShopGridRightModule), data: { breadcrumb: "Shop Grid" } },
  { path: 'shop-list', loadChildren: () => import('./components/pages/shop-list/shop-list.module').then(m => m.ShopListModule), data: { breadcrumb: "Shop List" } },
  { path: 'shop-list-left', loadChildren: () => import('./components/pages/shop-list-left/shop-list-left.module').then(m => m.ShopListLeftModule), data: { breadcrumb: "Shop List" } },
  { path: 'shop-list-right', loadChildren: () => import('./components/pages/shop-list-right/shop-list-right.module').then(m => m.ShopListRightModule), data: { breadcrumb: "Shop List" } },
  { path: 'product-details/:id', loadChildren: () => import('./components/pages/product-details/product-details.module').then(m => m.ProductDetailsModule), data: { breadcrumb: "Product Details" } },
  { path: 'product-details-v2/:id', loadChildren: () => import('./components/pages/product-details-v2/product-details-v2.module').then(m => m.ProductDetailsV2Module), data: { breadcrumb: "Product Details" } },
  { path: 'product-details-v3/:id', loadChildren: () => import('./components/pages/product-details-v3/product-details-v3.module').then(m => m.ProductDetailsV3Module), data: { breadcrumb: "Product Details" } },
  { path: '**', loadChildren: () => import('./components/pages/error/error.module').then(m => m.ErrorModule), data: { breadcrumb: "Error 404" } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
